<template>
  <div class="p-2">
    <div class="mb-2 fs-20">{{ $t("Danh sách chữ ký") }}</div>
    <div v-loading="isLoading" class="cs-list">
      <div
        @click="openModalCreateSignature"
        class="cs-image-container cs-create-signature"
      >
        <div class="flex items-center justify-center flex-col">
          <svg
            width="48"
            height="48"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 3.5C13.5277 3.5 11.111 4.23311 9.05538 5.60663C6.99976 6.98015 5.39761 8.93238 4.45151 11.2165C3.50542 13.5005 3.25787 16.0139 3.74019 18.4386C4.2225 20.8634 5.41301 23.0907 7.16117 24.8388C8.90933 26.587 11.1366 27.7775 13.5614 28.2598C15.9861 28.7421 18.4995 28.4946 20.7835 27.5485C23.0676 26.6024 25.0199 25.0002 26.3934 22.9446C27.7669 20.889 28.5 18.4723 28.5 16C28.4964 12.6859 27.1782 9.5086 24.8348 7.16518C22.4914 4.82177 19.3141 3.50364 16 3.5ZM16 27.5C13.7255 27.5 11.5021 26.8255 9.61095 25.5619C7.71978 24.2983 6.2458 22.5022 5.37539 20.4009C4.50498 18.2995 4.27724 15.9872 4.72097 13.7565C5.1647 11.5257 6.25997 9.47658 7.86828 7.86827C9.47658 6.25997 11.5257 5.1647 13.7565 4.72097C15.9872 4.27724 18.2995 4.50498 20.4009 5.37539C22.5022 6.24579 24.2983 7.71978 25.5619 9.61094C26.8255 11.5021 27.5 13.7255 27.5 16C27.4967 19.049 26.284 21.9721 24.1281 24.1281C21.9721 26.284 19.049 27.4967 16 27.5ZM21.5 16C21.5 16.1326 21.4473 16.2598 21.3536 16.3536C21.2598 16.4473 21.1326 16.5 21 16.5H16.5V21C16.5 21.1326 16.4473 21.2598 16.3536 21.3536C16.2598 21.4473 16.1326 21.5 16 21.5C15.8674 21.5 15.7402 21.4473 15.6465 21.3536C15.5527 21.2598 15.5 21.1326 15.5 21V16.5H11C10.8674 16.5 10.7402 16.4473 10.6465 16.3536C10.5527 16.2598 10.5 16.1326 10.5 16C10.5 15.8674 10.5527 15.7402 10.6465 15.6464C10.7402 15.5527 10.8674 15.5 11 15.5H15.5V11C15.5 10.8674 15.5527 10.7402 15.6465 10.6464C15.7402 10.5527 15.8674 10.5 16 10.5C16.1326 10.5 16.2598 10.5527 16.3536 10.6464C16.4473 10.7402 16.5 10.8674 16.5 11V15.5H21C21.1326 15.5 21.2598 15.5527 21.3536 15.6464C21.4473 15.7402 21.5 15.8674 21.5 16Z"
              fill="#20409B"
            />
          </svg>

          <div class="text-black">
            {{ $t("Tạo chữ ký") }}
          </div>
        </div>
      </div>
      <div
        v-for="(signature, index) in signatures"
        :key="index"
        class="cs-image-container"
      >
        <img
          class="cs-image"
          v-if="signature.signature_base64"
          :src="generateImageSrcBase64(signature.signature_base64)"
        />
      </div>
      <!-- <div v-if="isCreate" ref="CreateSignature">
        <div class="flex flex-col justify-center p-4">
          <div id="signature-pad">
            <canvas id="signature-canvas" width="1000" height="600"></canvas>
          </div>
          <div class="flex gap-2 justify-end mt-2">
            <el-button
              plain
              type="primary"
              @click="
                () => {
                  isCreate = false;
                  clearSignature();
                }
              "
              >{{ $t("Huỷ") }}</el-button
            >
            <el-button plain type="primary" @click="clearSignature()">{{
              $t("Xoá chữ ký")
            }}</el-button>
            <el-button type="primary" @click="saveSignature()">{{
              $t("Tạo chữ ký")
            }}</el-button>
          </div>
        </div>
      </div> -->
    </div>
    <ModalCreateSignature
      ref="ModalCreateSignature"
      @onSuccess="getDoctorSignatures"
    />
  </div>
</template>

<script>
import SignatureRequest from '../../api/request/SignatureRequest'
import ModalCreateSignature from './ModalCreateSignature.vue'
import appUtils from '@/utils/appUtils'

export default {
  components: { ModalCreateSignature },
  name: 'DoctorSignature',
  data () {
    return {
      signatures: [],
      isLoading: false,
      isCreate: false,
      appUtils
    }
  },
  mounted () {
    this.getDoctorSignatures()
  },
  methods: {
    async getDoctorSignatures () {
      try {
        this.isLoading = true
        const request = new SignatureRequest()
        const params = {
          user_id: this.$user?.id
        }
        const response = await request.getDoctorSignatures(params)
        this.signatures = response.data || []
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    generateImageSrcBase64 (content) {
      var prefix = 'data:image/png;base64,'

      return prefix + content
    },
    openModalCreateSignature () {
      this.$refs.ModalCreateSignature.handleOpen(this.$user?.id)
      // this.isCreate = true
      // this.$nextTick(() => {
      //   this.$refs.CreateSignature.scrollIntoView({
      //     behavior: 'smooth'
      //   })
      //   this.getDrawingSignature()
      // })
    },
    async handleSaveDrawImage (imageBase64) {
      try {
        this.isLoading = true
        const cleanedBase64String = this.removePrefix(imageBase64)
        const workerName =
          'worker' + window.moment().unix() + 'U' + this.$user.id

        // TODO: Need update Default key, crypto token
        const params = {
          properties: {
            // description: 'Nguyen Minh Quang',
            name: workerName,
            // LOCATION: "HODO-Ha Noi, Vietnam",
            DEFAULTKEY: process.env.VUE_APP_SIGNATURE_DEFAULT_KEY,
            CRYPTOTOKEN: process.env.VUE_APP_SIGNATURE_CRYPTO_TOKEN,
            TYPE: 'PROCESSABLE',
            ADD_VISIBLE_SIGNATURE: 'True',
            DIGESTALGORITHM: 'SHA256',
            IMPLEMENTATION_CLASS: 'org.signserver.module.pdfsigner.PDFSigner',
            AUTHTYPE: 'NOAUTH',
            DISABLEKEYUSAGECOUNTER: 'false',
            ALLOW_PROPERTY_OVERRIDE:
              'VISIBLE_SIGNATURE_RECTANGLE,VISIBLE_SIGNATURE_PAGE,LOCATION,REASON,description,CERTIFICATION_LEVEL',
            VISIBLE_SIGNATURE_CUSTOM_IMAGE_BASE64: cleanedBase64String,
            // REASON: "Quang Stamped",
            user_id: this.$user?.id
          }
        }

        const request = new SignatureRequest()

        const response = await request.createSignature(params)

        if (response.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('Tạo chữ kí thành công')
          })
          this.getDoctorSignatures()
          this.clearSignature()
          this.isCreate = false
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    toBinary (string) {
      const codeUnits = new Uint16Array(string.length)
      for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i)
      }
      return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)))
    },
    removePrefix (base64String) {
      let prefix = 'data:image/png;base64,'
      if (base64String.startsWith(prefix)) {
        return base64String.slice(prefix.length)
      }
      return base64String
    },
    getDrawingSignature () {
      let canvas = document.getElementById('signature-canvas')
      let ctx = canvas.getContext('2d')
      let isDrawing = false
      let lastX = 0
      let lastY = 0

      canvas.addEventListener('mousedown', startDrawing)
      canvas.addEventListener('mousemove', draw)
      canvas.addEventListener('mouseup', endDrawing)
      canvas.addEventListener('mouseout', endDrawing)

      canvas.addEventListener('touchstart', startDrawing)
      canvas.addEventListener('touchmove', draw)
      canvas.addEventListener('touchend', endDrawing)

      function startDrawing (e) {
        isDrawing = true
        let x = e.pageX - canvas.offsetLeft
        let y = e.pageY - canvas.offsetTop;
        [lastX, lastY] = [x, y]
      }

      function draw (e) {
        if (!isDrawing) return
        let x, y
        if (e.type.startsWith('touch')) {
          x = e.touches[0].pageX - canvas.offsetLeft
          y = e.touches[0].pageY - canvas.offsetTop
        } else {
          x = e.pageX - canvas.offsetLeft
          y = e.pageY - canvas.offsetTop
        }

        ctx.beginPath()
        ctx.moveTo(lastX, lastY)
        ctx.lineTo(x, y)
        ctx.strokeStyle = '#0000FF'
        ctx.lineWidth = 3
        ctx.lineCap = 'round'
        ctx.stroke();
        [lastX, lastY] = [x, y]
      }

      function endDrawing () {
        isDrawing = false
      }
    },

    clearSignature () {
      const canvas = document.getElementById('signature-canvas')
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      // document.getElementById('signature-output').src = ''
    },

    saveSignature () {
      let croppedData = this.getCroppedImage()
      this.handleSaveDrawImage(croppedData)
      // document.getElementById("signature-output").src = croppedData;
    },
    getCroppedImage () {
      let canvas = document.getElementById('signature-canvas')
      let ctx = canvas.getContext('2d')

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      let data = imageData.data

      let minX = canvas.width
      let minY = canvas.height
      let maxX = -1
      let maxY = -1
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          let index = (y * canvas.width + x) * 4
          if (data[index + 3] > 0) {
            if (x < minX) minX = x
            if (x > maxX) maxX = x
            if (y < minY) minY = y
            if (y > maxY) maxY = y
          }
        }
      }

      let croppedWidth = maxX - minX + 1
      let croppedHeight = maxY - minY + 1
      let croppedCanvas = document.createElement('canvas')
      let croppedCtx = croppedCanvas.getContext('2d')
      croppedCanvas.width = croppedWidth
      croppedCanvas.height = croppedHeight
      croppedCtx.putImageData(imageData, -minX, -minY)

      return croppedCanvas.toDataURL()
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.cs-image-container {
  cursor: pointer;
  padding: 8px;
  border: 1px dashed #000;
  // width: 300px;
  height: 200px;
}

.cs-create-signature {
  border: 2px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

#signature-pad {
  border: 1px dashed #000;
}

.cs-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}
</style>