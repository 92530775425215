<template>
  <div ref="Container">
    <el-dialog
      title="Tạo chữ ký"
      :visible.sync="isOpen"
      fullscreen
      :before-close="handleClose"
      top="50px"
    >
      <template slot="title">
        <div class="fs-20 font-bold uppercase">
          {{ title }}
        </div>
      </template>
      <div v-loading="isLoading" class="text-black">
        <div class="flex flex-col justify-center cs-container-signature">
          <div id="signature-pad" class="mx-auto">
            <canvas ref="signatureCanvas" id="signature-canvas"></canvas>
          </div>
          <br />
        </div>
        <div class="flex flex-row items-center gap-2 justify-end mt-4">
          <el-button plain type="primary" @click="handleClose">{{
            $t("Huỷ")
          }}</el-button>
          <el-button plain type="primary" @click="clearSignature()">{{
            $t("Xoá chữ ký")
          }}</el-button>
          <el-button type="primary" @click="saveSignature()">{{
            $t("Tạo chữ ký")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import appUtils from '@/utils/appUtils'
import SignatureRequest from '../../api/request/SignatureRequest'

export default {
  name: 'ModalCreateSignature',
  components: {},
  data () {
    return {
      isOpen: false,
      appUtils,
      isLoading: false,
      imageFileSelected: null,
      signatory_id: null,
      title: this.$t('Tạo chữ ký'),
      personID: null
    }
  },
  mounted () {},
  beforeDestroy () {
    window.removeEventListener('resize', this.setCanvasSize)
    this.cleanupScrollPrevention()
  },
  methods: {
    setCanvasSize () {
      const canvas = this.$refs.signatureCanvas
      if (canvas) {
        const ctx = canvas.getContext('2d')
        const pixelRatio = window.devicePixelRatio || 1

        // Điều chỉnh kích thước canvas dựa trên devicePixelRatio
        canvas.width = (window.innerWidth - 120) * pixelRatio
        canvas.height = (window.innerHeight - 200) * pixelRatio

        // Điều chỉnh lại kích thước hiển thị của canvas để không bị kéo giãn
        canvas.style.width = window.innerWidth - 120 + 'px'
        canvas.style.height = window.innerHeight - 200 + 'px'

        // Bật làm mịn ảnh
        ctx.imageSmoothingEnabled = true

        // Điều chỉnh tỉ lệ của context để vẽ đúng với tỉ lệ pixel
        ctx.scale(pixelRatio, pixelRatio)
      }
    },
    handleClose () {
      this.isOpen = false
      this.clearSignature()
    },
    handleOpen (userID, titleProp, personIDProp) {
      this.signatory_id = userID
      this.personID = personIDProp

      if (titleProp) {
        this.title = titleProp
      }
      this.isOpen = true
      this.$nextTick(() => {
        this.preventScrollOnCanvas()
        this.setCanvasSize()
        window.addEventListener('resize', this.setCanvasSize)
        this.getDrawingSignature()
      })
    },
    async handleSaveDrawImage (imageBase64) {
      try {
        this.isLoading = true
        const cleanedBase64String = this.removePrefix(imageBase64)
        const workerName =
          'worker' + window.moment().unix() + 'U' + (this.signatory_id || '')

        const params = {
          properties: {
            // description: 'Nguyen Minh Quang',
            name: workerName,
            // LOCATION: "HODO-Ha Noi, Vietnam",
            DEFAULTKEY: process.env.VUE_APP_SIGNATURE_DEFAULT_KEY,
            CRYPTOTOKEN: process.env.VUE_APP_SIGNATURE_CRYPTO_TOKEN,
            TYPE: 'PROCESSABLE',
            ADD_VISIBLE_SIGNATURE: 'True',
            DIGESTALGORITHM: 'SHA256',
            IMPLEMENTATION_CLASS: 'org.signserver.module.pdfsigner.PDFSigner',
            AUTHTYPE: 'NOAUTH',
            DISABLEKEYUSAGECOUNTER: 'false',
            ALLOW_PROPERTY_OVERRIDE:
              'VISIBLE_SIGNATURE_RECTANGLE,VISIBLE_SIGNATURE_PAGE,LOCATION,REASON,description,CERTIFICATION_LEVEL',
            VISIBLE_SIGNATURE_CUSTOM_IMAGE_BASE64: cleanedBase64String,
            // REASON: "Quang Stamped",
            user_id: this.signatory_id,
            person_id: this.personID
          }
        }

        const request = new SignatureRequest()

        const response = await request.createSignature(params)

        if (response.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('Tạo chữ kí thành công')
          })
          this.$emit('onSuccess', response.data?.data)
          this.handleClose()
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: this.$t('Tạo chữ kí thất bại')
        })
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },

    async showModalDrawImage (imageData, index) {
      if (imageData && typeof imageData === 'string') {
        try {
          const newImageSrc = new URLSearchParams(imageData).toString()
          const formatImageSrc = newImageSrc.substring(
            0,
            newImageSrc.length - 1
          )
          const response = await this.$rf
            .getRequest('DoctorRequest')
            .postUrlImage(formatImageSrc)
          const base64File = response.data?.data || ''
          const fileName = imageData.substring(imageData.lastIndexOf('/') + 1)
          const newFile = appUtils.convertBase64tToFile(base64File, fileName)
          this.imageFileSelected = newFile
        } catch (error) {
          console.log(error)
        }
      } else if (!imageData) {
        const newFile = new File([0], `image-${index + Math.random()}.png`, {
          type: 'image/png'
        })
        this.imageFileSelected = newFile
      } else {
        this.imageFileSelected = imageData
      }

      //   this.selectedImage = index
      //   window.$('#modalDrawImage').modal('show')
    },

    closeModalDrawImage () {
      this.imageFileSelected = ''
      //   this.selectedImage = ''
      //   window.$('#modalDrawImage').modal('hide')
    },
    toBinary (string) {
      const codeUnits = new Uint16Array(string.length)
      for (let i = 0; i < codeUnits.length; i++) {
        codeUnits[i] = string.charCodeAt(i)
      }
      return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)))
    },
    removePrefix (base64String) {
      let prefix = 'data:image/png;base64,'
      if (base64String.startsWith(prefix)) {
        return base64String.slice(prefix.length)
      }
      return base64String
    },
    getDrawingSignature () {
      let canvas = document.getElementById('signature-canvas')
      let ctx = canvas.getContext('2d')
      let isDrawing = false
      let lastX = 0
      let lastY = 0

      canvas.addEventListener('mousedown', startDrawing)
      canvas.addEventListener('mousemove', draw)
      canvas.addEventListener('mouseup', endDrawing)
      canvas.addEventListener('mouseout', endDrawing)

      canvas.addEventListener('touchstart', startDrawing)
      canvas.addEventListener('touchmove', draw)
      canvas.addEventListener('touchend', endDrawing)

      function startDrawing (e) {
        isDrawing = true
        let rect = canvas.getBoundingClientRect()
        let x = e.clientX - rect.left
        let y = e.clientY - rect.top;
        [lastX, lastY] = [x, y]
      }

      function draw (e) {
        if (!isDrawing) return
        let x, y
        if (e.type.startsWith('touch')) {
          x = e.touches[0].pageX - canvas.offsetLeft
          y = e.touches[0].pageY - canvas.offsetTop
        } else {
          let rect = canvas.getBoundingClientRect()
          x = e.clientX - rect.left
          y = e.clientY - rect.top
        }

        ctx.beginPath()
        ctx.moveTo(lastX, lastY)
        ctx.lineTo(x, y)
        ctx.strokeStyle = '#0000FF'
        ctx.lineWidth = 3
        ctx.lineCap = 'round'
        ctx.stroke();
        [lastX, lastY] = [x, y]
      }

      function endDrawing () {
        isDrawing = false
      }
    },

    clearSignature () {
      const canvas = document.getElementById('signature-canvas')
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },

    saveSignature () {
      let croppedData = this.getCroppedImage()
      this.handleSaveDrawImage(croppedData)
    },
    getCroppedImage () {
      let canvas = document.getElementById('signature-canvas')
      let ctx = canvas.getContext('2d')

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      let data = imageData.data

      let minX = canvas.width
      let minY = canvas.height
      let maxX = -1
      let maxY = -1
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          let index = (y * canvas.width + x) * 4
          if (data[index + 3] > 0) {
            if (x < minX) minX = x
            if (x > maxX) maxX = x
            if (y < minY) minY = y
            if (y > maxY) maxY = y
          }
        }
      }

      let croppedWidth = maxX - minX + 1
      let croppedHeight = maxY - minY + 1
      let croppedCanvas = document.createElement('canvas')
      let croppedCtx = croppedCanvas.getContext('2d')
      croppedCanvas.width = croppedWidth
      croppedCanvas.height = croppedHeight
      croppedCtx.putImageData(imageData, -minX, -minY)

      return croppedCanvas.toDataURL()
    },
    preventDefault (e) {
      e.preventDefault()
    },
    preventScrollOnCanvas () {
      const canvas = this.$refs.signatureCanvas
      if (canvas) {
        canvas.addEventListener('touchmove', this.preventDefault, {
          passive: false
        })
        canvas.addEventListener('mousemove', this.preventDefault, {
          passive: false
        })
      }
    },
    cleanupScrollPrevention () {
      const canvas = this.$refs.signatureCanvas
      if (canvas) {
        canvas.removeEventListener('touchmove', this.preventDefault)
        canvas.removeEventListener('mousemove', this.preventDefault)
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
.cs-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.cs-image-container {
  cursor: pointer;
  padding: 8px;
  border: 1px dashed #000;
  width: 300px;
  height: 200px;
}

.cs-create-signature {
  border: 2px dashed #20409b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

#signature-pad {
  border: 1px dashed #000;
  width: fit-content;
}
// ::v-deep {
//   .el-dialog__body {
//     padding: 0 !important;
//   }
// }

.cs-container-signature {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: auto;
}
</style>